import rolesStore from '@/store/roles'

export const getFilters = headers => {
  const filters = {};
  headers.forEach(header => {
    if (header.filter && (header.filter.value != null || (header.filter.methods && header.filter.method)) && header.filter.value !== undefined) {
      filters[header.value] = {
        type: header.filter.type,
        method: header.filter.method,
        value: header.filter.value,
      };
    }
  });

  return filters;
}
export const getOptions = ($route, options = {}) => {
  const customOptions = {};
  if (localStorage.getItem(`itemsPerPage_${$route.name}`)) {
    customOptions.itemsPerPage = parseInt(localStorage.getItem(`itemsPerPage_${$route.name}`), 10);
  }
  const {
    page, itemsPerPage, sortBy, sortDesc,
  } = { ...Object.fromEntries(new URLSearchParams(window.location.hash.substring(1).replace(/[\[\]]/ig, ''))), ...$route.query };

  if (page) {
    customOptions.page = parseInt(page, 10);
  }
  if (itemsPerPage) {
    customOptions.itemsPerPage = parseInt(itemsPerPage, 10);
  }
  if (sortBy) {
    customOptions.sortBy = [sortBy];
  }
  if (sortDesc) {
    customOptions.sortDesc = [sortDesc === 'true'];
  }

  return { ...options, ...customOptions };
}

export const checkPermission = permissions => {
  if (!permissions) {
    return false;
  }
  if (typeof permissions === 'string') {
    permissions = [permissions];
  }

  return rolesStore.state.permissions.some(permission => permissions.findIndex(perm => perm === permission.name) !== -1)
}
