<template>
  <v-dialog v-model="data.dialog" persistent max-width="900px">
    <v-card v-if="loading" :color="isDelete ? 'error' : 'info'" dark>
      <v-card-text>
        <!--        <span class="font-weight-bold text-white">{{ $t('loading') }}</span>-->
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
    <v-card v-if="!loading">
      <v-card-title
        :class="{'form-danger': isDelete, 'form-info': isUpdate, 'form-primary': isCreate, 'form-info': isRestore}"
      >
        <span v-if="isCreate" class="">{{ $('create') }}</span>
        <span v-if="isUpdate" class="">{{ $('update') }}</span>
        <span v-if="isDelete" class="">{{ $('delete') }}</span>
        <span v-if="isRestore" class="">{{ $('restore') }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          v-if="isDelete"
          ref="form"
          v-model="valid"
          class="mt-2"
        >
          <v-container>
            <v-row>
              <v-col
                class="my-0 py-0"
                cols="12"
              >
                <h2 class="mt-3">
                  {{ $('delete_msg', [car.type]) }}
                </h2>
              </v-col>

              <v-col
                class="my-0 py-0"
                cols="12"
              >
                <v-text-field
                  v-model="bookNumber"
                  :rules="rules.book_number"
                  class="mt-5 mb-0 pb-0"
                  :label="$('book_name')"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <div v-if="isRestore">
          <h2 class="mt-3">
            {{ $('restore_msg', [car.type]) }}
          </h2>
        </div>
        <v-form
          v-if="!isDelete && !isRestore"
          ref="form"
          v-model="valid"
          class="mt-2"
        >
          <v-container>
            <v-row>
              <v-col
                cols="6"
                sm="12"
                md="6"
              >
                <v-text-field
                  v-model="form.model"
                  v-max-length="200"
                  :label="$('model')"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col
                cols="6"
                sm="12"
                md="6"
              >
                <v-text-field
                  v-model="form.color"
                  v-max-length="200"
                  :label="$('color')"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col
                cols="6"
                sm="12"
                md="6"
              >
                <v-text-field
                  v-model="form.type"
                  v-max-length="200"
                  :label="$('type')"
                  :rules="rules.type"
                  required
                  outlined
                ></v-text-field>
              </v-col>

              <v-col
                cols="6"
                sm="12"
                md="6"
              >
                <v-text-field
                  v-model="form.number"
                  v-max-length="200"
                  :label="$('number')"
                  :rules="rules.number"
                  required
                  outlined
                ></v-text-field>
              </v-col>

              <v-col
                cols="6"
                sm="12"
                md="6"
              >
                <v-text-field
                  v-model="form.city"
                  v-max-length="200"
                  :label="$('city')"
                  outlined
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-combobox
                  v-model="form.owner"
                  multiple
                  chips
                  return-object
                  :items="owners"
                  :label="$('owner')"
                  outlined
                  :rules="rules.owner"
                  hide-details
                >
                </v-combobox>
              </v-col>

              <v-col cols="6">
                <v-autocomplete
                  v-model="form.peoples"
                  :items="people"
                  :loading="loadingPeople"
                  :search-input.sync="searchPeople"
                  :label="$('peoples')"
                  item-value="id"
                  item-text="name"
                  multiple
                  outlined
                  hide-details
                  return-object
                  :menu-props="{contentClass:'list-style'}"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      label
                      close
                      :input-value="data.selected"
                      @click="data.select"
                      @click:close="removePeople(data.item)"
                    >
                      <v-avatar left>
                        <v-img :src="data.item.media && data.item.media.length > 0 ? data.item.media[0].original_url : require('@/assets/images/avatars/3.png')"></v-img>
                      </v-avatar>
                      {{ data.item.name }}
                    </v-chip>
                  </template>

                  <template v-slot:item="data">
                    <v-list-item-avatar>
                      <v-img :src="data.item.media && data.item.media.length > 0 ? data.item.media[0].original_url : require('@/assets/images/avatars/3.png')"></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data.item.phone_number }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6">
                <date-picker
                  v-model="form.expire_date"
                  style="width: 100%;"
                  class="mb-5"
                  placeholder="تاريخ الانتهاء"
                  value-type="format"
                ></date-picker>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="light"
          outlined
          @click="dialogModal(false)"
        >
          {{ $t('form.cancel') }}
        </v-btn>
        <v-btn
          v-if="isCreate"
          color="primary"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.create') }}
        </v-btn>
        <v-btn
          v-if="isUpdate"
          color="info"
          :disabled="!valid || submitLoading"
          :loading="submitLoading"
          @click="submit"
        >
          {{ $t('form.save') }}
        </v-btn>
        <v-btn
          v-if="isDelete"
          color="error"
          :loading="submitLoading"
          :disabled="submitLoading"
          @click="submit"
        >
          {{ $t('form.delete') }}
        </v-btn>
        <v-btn
          v-if="isRestore"
          color="info"
          :loading="submitLoading"
          :disabled="submitLoading"
          @click="submit"
        >
          {{ $t('form.restore') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import axios from '@axios'
// eslint-disable-next-line import/extensions
import toast from '@/store/toast'
// eslint-disable-next-line import/extensions
import store from '@/store/index'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/ar-sa'
import DatePicker from 'vue2-datepicker'

export default {
  name: 'CarsDialog',
  components: { DatePicker },
  props: {
    data: {
      type: Object,
      default: () => ({
        dialog: false,
        id: null,
        type: 'create',
      }),
    },
    dialogModal: {
      type: Function,
      default: state => {
        this.data.dialog = state
      },
    },
    translationKey: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:table'],
  setup() {
    return {
    }
  },
  data: () => ({
    loading: true,
    submitLoading: false,
    valid: false,
    car: null,
    loadingPeople: false,
    searchPeople: '',
    people: [],
    owners: [],
    form: {
      model: '',
      color: '',
      type: '',
      number: '',
      city: '',
      owner: '',
      peoples: [],
      expire_date: '',
    },
    bookNumber: null,
  }),
  computed: {
    isCreate() {
      return this.data.type === 'create'
    },
    isUpdate() {
      return this.data.type === 'update'
    },
    isDelete() {
      return this.data.type === 'delete'
    },
    isRestore() {
      return this.data.type === 'restore'
    },

    rules() {
      const rules = {};
      rules.model = [
        v => !!v || this.$t('form.required', ['الموديل']),
      ];
      rules.color = [
        v => !!v || this.$t('form.required', ['الون']),
      ];
      rules.type = [
        v => !!v || this.$t('form.required', ['النوع']),
      ];
      rules.number = [
        v => !!v || this.$t('form.required', ['الرقم']),
      ];
      rules.city = [
        v => !!v || this.$t('form.required', ['المحافظة']),
      ];
      rules.owner = [
        v => !!v || this.$t('form.required', ['الجهة المالكة']),
      ];
      rules.book_number = [
        v => !!v || this.$t('form.required', ['رقم الكتاب']),
      ];

      return rules;
    },
  },
  watch: {
    data: {
      deep: true,
      immediate: true,
      async handler(val) {
        try {
          this.loading = true;
          if (val.id) {
            if (this.isCreate) {
              const people = await axios.get(`/peoples/${val.id}`)
              this.form.peoples.push(people.data.data)
            } else {
              const cars = await axios.get(`/cars/${val.id}`);
              this.form = cars.data.data;
              this.car = cars.data.data
            }
          }
          if (!val.dialog) {
            this.resetForm();
          }
        } catch (error) {
          // await toast.dispatch('error', error.response ? error.response.data.message : error.message)
        } finally {
          this.loading = false;
        }
      },
    },
    searchPeople: {
      immediate: true,
      deep: true,
      handler() {
        this.fetchPeople();
      },
    },
  },
  beforeMount() {
    this.fetchPeople();
  },

  async mounted() {
    const owners = await axios.get('/cars/owners');
    this.owners = owners.data.data
  },
  methods: {
    async submit() {
      try {
        this.submitLoading = true;
        if (this.isDelete) {
          if (this.$refs.form.validate()) {
            await axios.post(`/cars/delete/${this.data.id}`, {
              book_number: this.bookNumber,
            });
            await toast.dispatch('success', this.$t('form.success.delete'))
            this.$emit('update:table')
            this.dialogModal(false)
          }
        }
        else if (this.isRestore) {
          await axios.post(`/cars/restore/${this.data.id}`);
          await toast.dispatch('success', this.$t('form.success.restore'))
          this.$emit('update:table')
          this.dialogModal(false)
        }

        if (this.$refs.form.validate()) {
          try {
            const payload = {
              ...this.form,
              owner: this.form.owner.map(m => m),
              people: this.form.peoples.map(people => people.id),
            };
            if (this.isCreate) {
              await axios.post('/cars/create', payload)
              await toast.dispatch('success', this.$t('form.success.create'));
            } else if (this.isUpdate) {
              await axios.post(`/cars/edit/${this.data.id}`, payload);
              await toast.dispatch('success', this.$t('form.success.update'))
            }
            this.$emit('update:table')
            this.dialogModal(false)
          } catch (error) {
            //   await toast.dispatch('error', error.response ? error.response.data.message : error.message)
          }
        }
      } finally {
        this.submitLoading = false;
      }
    },
    resetForm() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.form = {
        model: '',
        color: '',
        type: '',
        number: '',
        city: '',
        owner: '',
        peoples: [],
        expire_date: '',
      };
    },
    async fetchPeople() {
      this.loadingPeople = true;
      const response = await axios.get(`/peoples?search=${this.searchPeople}`)
      this.people = [
        ...this.form.peoples,
        ...response.data.data.data,
      ];
      this.loadingPeople = false;
    },
    removePeople(item) {
      const index = this.form.peoples.findIndex(i => i.id === item.id);
      this.form.peoples.splice(index, 1);
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.modal.${key}`, parameters);
    },
  },
}
</script>

<style scoped>

</style>
