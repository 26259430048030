<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="data"
      :options.sync="options"
      :server-items-length="dataTotal"
      :loading="loading"
      :disable-filtering="true"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions:[10, 20, 50, 100, -1],
        itemsPerPageText: $t('dataTable.itemsPerPageText'),
        pageText: $t('dataTable.pageText'),
        itemsPerPageAllText: $t('dataTable.itemsPerPageAllText'),
      }"
      :hide-default-header="!$vuetify.breakpoint.mobile"
      @update:page="fetch"
      @update:items-per-page="fetch"
    >
      <template v-if="!$vuetify.breakpoint.mobile" v-slot:header="{ props }">
        <datatable-header :props="props" :options.sync="options" :translation-key="translationKey" @update:filters="fetch"></datatable-header>
      </template>
      <template v-slot:item.checkpoint.name="{ item }">
        <v-chip
          label
          color="accent"
          small
          dark
        >
          {{ item.checkpoint ? item.checkpoint.name : '-' }}
        </v-chip>
      </template>
      <template v-slot:item.latest_open="{ item }">
        {{ item.latest_open | format_date }}
      </template>

      <template v-slot:item.latest_entry="{ item }">
        {{ item.latest_entry | format_date }}
      </template>

      <template v-slot:item.name="{ item }">
        {{ item.name ? item.name : '-' }}
      </template>
      <template v-slot:item.checkpoint.name="{ item }">
        {{ (item.checkpoint ? item.checkpoint.name : '-') | short_string(100) }}
      </template>
      <template v-slot:item.active="{ item }">
        <v-btn
          depressed
          data-toggle="tooltip"
          data-placement="top"
          title="تغيير الحالة"
          :color="item.active ? 'success' : 'error'"
          rounded
          small
          outlined
          @click="toggleActive(item)"
        >
          {{ item.active ? 'فعال' : 'غير فعال' }}
          <v-icon
            dark
            right
          >
            {{ item.active ? 'mdi-power' : 'mdi-power-off' }}
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                color="dark"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <menu-item :label="$('update')" color="info" icon="mdi-pencil" @click="dialogModal(true, 'update', item.id)"></menu-item>
              <menu-item :label="$('delete')" color="error" icon="mdi-delete" @click="dialogModal(true, 'delete', item.id)"></menu-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <device-dialog
      :dialog-modal="dialogModal"
      :data="dialogData"
      :translation-key="translationKey"
      @update:table="fetch"
    ></device-dialog>
  </v-card>
</template>

<script>
import axios from '@axios'
import DeviceDialog from '@/views/pages/devices/dialog.vue'
import DatatableHeader from '@/components/dataTables/DatatableHeader.vue'

import { ObjectToQuery } from '@/plugins/helper';
import { getFilters, getOptions } from '@/components/dataTables/helper'
import toast from '@/store/toast'
import MenuItem from '@/components/menu/menuItem.vue';

export default {
  name: 'Devices',
  components: { DatatableHeader, DeviceDialog, MenuItem },
  data: () => ({
    translationKey: 'pages.devices',
    filters: false,
    data: [],
    dataTotal: 0,
    loading: true,
    options: {},
    tempOptions: '',
    search: '',
    dialogData: {
      dialog: false,
      type: 'create',
      id: null,
    },
    headers: [
      {
        text: 'name',
        align: 'center',
        sortable: false,
        value: 'name',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },

      {
        text: 'latest_entry',
        align: 'center',
        sortable: false,
        value: 'latest_entry',
        sort: true,
        filter: {
          menu: false,
          type: 'date',
          methods: ['between', 'greater', 'less', 'equal', 'notEqual'],
        },
      },
      {
        text: 'latest_open',
        align: 'center',
        sortable: false,
        value: 'latest_open',
        sort: true,
        filter: {
          menu: false,
          type: 'date',
          methods: ['between', 'greater', 'less', 'equal', 'notEqual'],
        },
      },
      {
        text: 'device_id',
        align: 'center',
        sortable: false,
        value: 'device_id',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'checkpoint',
        value: 'checkpoint.name',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'select',
          api: async $axios => {
            const check = await $axios.get('/checkpoints')

            return check.data.data.map(checkpoint => checkpoint.name)
          },
          options: [],
        },
      },
      {
        text: 'active',
        value: 'active',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'switch',
          label: 'فعال',
        },
      },
      {
        text: 'actions',
        value: 'actions',
        align: 'center',
      },
    ],
  }),
  mounted() {
    this.options = getOptions(this.$route, this.options);
    this.fetch();
  },
  methods: {
    async fetch() {
      if (JSON.stringify(this.options) !== this.tempOptions) {
        this.tempOptions = JSON.stringify(this.options);
        await this.$router.replace({ query: this.options })
      }
      window.scrollTo(0, 0);
      localStorage.setItem(`itemsPerPage_${this.$route.name}`, this.options.itemsPerPage);
      this.loading = true;
      const filters = getFilters(this.headers);
      const query = ObjectToQuery({
        ...this.options,
        filters,
      })
      const response = await axios.get(`/devices/dt?${query}`);
      this.data = response.data.data.data
      this.dataTotal = response.data.data.total
      this.loading = false
    },
    async toggleActive(item) {
      const old = item.active;
      try {
        item.active = !old;
        await axios.post(`/devices/edit/${item.id}`, { active: !old });
        await toast.dispatch('success', this.$t('form.success.change'))
      } catch (e) {
        item.active = old;
      }
    },
    dialogModal(dialog, type = this.dialogData.type, id = null) {
      this.dialogData.dialog = dialog;
      this.dialogData.type = type;
      this.dialogData.id = id;
    },
    $(key) {
      return this.$t(`${this.translationKey}.${key}`);
    },
  },

}
</script>
