import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    role: JSON.parse(localStorage.getItem('role')),
    permissions: JSON.parse(localStorage.getItem('permissions') || '[]'),
  },
  actions: {
  },
  mutations: {
    SET_ROLES_PERMISSIONS(ctx, payload) {
      localStorage.setItem('role', JSON.stringify(payload))
      localStorage.setItem('permissions', JSON.stringify(payload.permissions))
      this.state.permissions = payload.permissions;
      this.state.role = payload;
    },
  },

})
