var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"md":"3","sm":"6","cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',{staticClass:"position-relative"},[_c('v-avatar',{staticClass:"avatar-center",staticStyle:{"z-index":"10"},attrs:{"size":"60","color":"primary"}},[_c('v-img',{attrs:{"src":_vm.user.image ? _vm.user.image.original_url : require('@/assets/images/avatars/3.png')}})],1),_c('div',{staticClass:"d-flex justify-space-between flex-wrap pt-2"},[_c('div',{staticClass:"me-2 mx-5"},[_c('v-card-title',{staticClass:"pt-0 px-0"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]),_c('v-card-subtitle',{staticClass:"text-xs pa-0"},[_c('v-chip',{attrs:{"color":_vm.user.role.color,"small":""}},[_vm._v(" "+_vm._s(_vm.user.role.description)+" ")])],1)],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"},on:{"click":_vm.logout}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-exit-to-app ")])],1)]}}])},[_c('span',[_vm._v("اضغط لتسجيل الخروج")])])],1)],1)],1)],1),(_vm.checkPermission('read_statistics'))?_vm._l((_vm.statistics),function(statistic,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"3","sm":"6"}},[_c('statistics-card',{attrs:{"title":statistic.title,"color":statistic.color,"icon":statistic.icon,"statistics":statistic.statistics,"show-overlay":statistic.loading},on:{"update:showOverlay":function($event){return _vm.$set(statistic, "loading", $event)},"update:show-overlay":function($event){return _vm.$set(statistic, "loading", $event)}}})],1)}):_vm._e()],2),(_vm.checkPermission('read_statistics'))?[_c('v-divider',{staticClass:"mt-2"}),_c('v-card-title',{staticClass:"pt-0 px-0"},[_c('br'),_vm._v(" الاحصائيات اليومية ")]),_c('v-row',_vm._l((_vm.DailyStatistics),function(statistic,index){return _c('v-col',{key:index,attrs:{"cols":"12","md":"3","sm":"6"}},[_c('statistics-card',{attrs:{"title":statistic.title,"color":statistic.color,"icon":statistic.icon,"statistics":statistic.statistics,"show-overlay":statistic.loading},on:{"update:showOverlay":function($event){return _vm.$set(statistic, "loading", $event)},"update:show-overlay":function($event){return _vm.$set(statistic, "loading", $event)}}})],1)}),1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card-text',{staticClass:"text-center pt-15",staticStyle:{"color":"red"}},[_vm._v(" العجلات التي ستنتهي مدتها قريبا ")]),_c('v-data-table',{attrs:{"headers":_vm.headersCars,"items":_vm.itemsCars,"loading":_vm.loading,"footer-props":{
            showFirstLastPage: true,
            itemsPerPageOptions:[1,2,3,4,5,6,7,8,9,10],
            itemsPerPageText: _vm.$t('dataTable.itemsPerPageText'),
            pageText: _vm.$t('dataTable.pageText')
          }},scopedSlots:_vm._u([{key:"item.expire_date",fn:function(ref){
          var item = ref.item;
return [(item.expire_date)?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm._f("format_date")(item.expire_date))+" ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [(item.created_at)?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm._f("format_date")(item.created_at))+" ")]):_vm._e()]}},{key:"item.active",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('update_peoples'),expression:"'update_peoples'"}],attrs:{"depressed":"","color":item.active ? 'success' : 'error',"rounded":"","x-small":"","outlined":""}},[_vm._v(" "+_vm._s(item.active ? 'مفعل' : 'معطل')+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" "+_vm._s(item.active ? 'mdi-check' : 'mdi-block-helper')+" ")])],1)]}}],null,false,1642456402)})],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","md":"6"}},[_c('v-card-text',{staticClass:"text-center pt-15",staticStyle:{"color":"red"}},[_vm._v(" الاشخاص الذين ستنتهي مدتهم قريبا ")]),_c('v-data-table',{attrs:{"headers":_vm.headersPeoples,"items":_vm.itemsPeoples,"loading":_vm.loading,"footer-props":{
            showFirstLastPage: true,
            itemsPerPageOptions:[1,2,3,4,5,6,7,8,9,10],
            itemsPerPageText: _vm.$t('dataTable.itemsPerPageText'),
            pageText: _vm.$t('dataTable.pageText')
          }},scopedSlots:_vm._u([{key:"item.expire_date",fn:function(ref){
          var item = ref.item;
return [(item.expire_date)?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm._f("format_date")(item.expire_date))+" ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
          var item = ref.item;
return [(item.created_at)?_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" "+_vm._s(_vm._f("format_date")(item.created_at))+" ")]):_vm._e()]}},{key:"item.active",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('update_peoples'),expression:"'update_peoples'"}],attrs:{"depressed":"","color":item.active ? 'success' : 'error',"rounded":"","x-small":"","outlined":""}},[_vm._v(" "+_vm._s(item.active ? 'مفعل' : 'معطل')+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" "+_vm._s(item.active ? 'mdi-check' : 'mdi-block-helper')+" ")])],1)]}},{key:"item.identifier.name",fn:function(ref){
          var item = ref.item;
return [(item.identifier)?_c('v-chip',{attrs:{"label":"","color":"black","small":"","dark":""}},[_vm._v(" "+_vm._s(item.identifier.name)+" ")]):_vm._e()]}},{key:"item.name",fn:function(ref){
          var item = ref.item;
return [(item.name)?_c('v-chip',{attrs:{"label":"","color":"black","small":"","dark":""}},[_vm._v(" "+_vm._s(item.name.substring(0,15)+'...')+" ")]):_vm._e()]}},{key:"item.enter_all_cars",fn:function(ref){
          var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.enter_all_cars ? 'success' : 'error',"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.enter_all_cars ? 'مسموح' : 'غير مسموح')+" ")])]}}],null,false,4055366972)})],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }