import {
  mdiAccountGroupOutline,
  mdiRecord,
  mdiHomeOutline, mdiCog,
  mdiAlarmLightOutline,
  mdiAccountBox,
  mdiAccountMultiple,
  mdiCarMultiple,
  mdiPoll,
  mdiCameraFrontVariant,
} from '@mdi/js'

export default [
  {
    subheader: 'menu.home1',
  },
  {
    title: 'menu.home',
    icon: mdiHomeOutline,
    color: 'info',
    to: 'home',
  },
  {
    subheader: 'menu.navigation',
  },
  {
    title: 'menu.checkpoints',
    icon: mdiAlarmLightOutline,
    color: 'error',
    children: [
      {
        title: 'menu.view_checkpoints',
        to: 'checkpoints.index',
        color: 'error',
      },
    ],
  },
  {
    title: 'menu.identifiers',
    to: 'Identifiers.index',
    color: 'info',
    icon: mdiAccountBox,
  },

  {
    title: 'menu.people.title',
    icon: mdiAccountMultiple,
    color: 'accent',
    children: [
      {
        title: 'menu.people.people',
        to: 'peoples.index',
        color: 'info',
      },
      {
        title: 'menu.people.citizens',
        to: 'citizens.index',
        color: 'info',
      },
      {
        title: 'menu.people.deleted',
        to: 'peoples.deleted',
        color: 'error',
      },
    ],
  },

  {
    title: 'ManageCars',
    icon: mdiCarMultiple,
    color: 'primary',
    children: [
      {
        title: 'menu.cars.index',
        to: 'cars.index',
        color: 'primary',
      },
      {
        title: 'menu.cars.citizen',
        to: 'cars.citizen',
        color: 'info',
      },
      {
        title: 'menu.cars.deleted',
        to: 'cars.deleted',
        color: 'error',
      },
    ],
  },

  {
    title: 'menu.statistics.title',
    icon: mdiPoll,
    color: 'success',
    children: [
      {
        title: 'menu.statistics.people',
        to: 'statistics.people',
        color: 'success',
      },
      {
        title: 'menu.statistics.citizen',
        to: 'statistics.citizen',
        color: 'success',
      },
      {
        title: 'menu.statistics.authorized',
        to: 'statistics.authorized',
        color: 'success',
      },
      {
        title: 'menu.statistics.funerals',
        to: 'statistics.funerals',
        color: 'success',
      },
      {
        title: 'menu.statistics.enterByCall',
        to: 'statistics.enterByCall',
        color: 'success',
      },
    ],
  },

  {
    title: 'menu.devices',
    to: 'devices.index',
    color: 'error',
    icon: mdiCameraFrontVariant,
  },
  {
    title: 'menu.users',
    icon: mdiAccountGroupOutline,
    color: 'error',
    children: [
      {
        title: 'menu.view_users',
        to: 'users.index',
        color: 'error',
      },
      {
        title: 'menu.roles',
        to: 'roles.index',
        color: 'error',
      },

      // {
      //   title: 'menu.permissions',
      //   to: 'permissions.index',
      //   icon: mdiKeyChainVariant,
      // },
    ],
  },
  {
    title: 'menu.site.settings',
    to: 'site.settings',
    color: 'error',
    class: 'spin',
    icon: mdiCog,
  },

]
