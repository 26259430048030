<template>
  <v-card>
    <v-card-title>
      <v-btn
        v-can="'create_cars'"
        color="primary"
        dark
        @click="dialogModal(true, 'create', $route.params.id)"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
        {{ $('create') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="#098064"
            small
            class="mx-2"
            v-bind="attrs"
            style="color: white"
            v-on="on"
            @click.prevent="export_all()"
          >
            تصدير
            <v-icon
              right
            >
              mdi-file-excel
            </v-icon>
          </v-btn>
        </template>
        <span>  تصدير  الاشخاص والمركبات  </span>
      </v-tooltip>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="cars"
      :options.sync="options"
      :server-items-length="carsTotal"
      :loading="loading"
      :disable-filtering="true"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions:[10, 20, 50, 100, -1],
        itemsPerPageText: $t('dataTable.itemsPerPageText'),
        pageText: $t('dataTable.pageText'),
        itemsPerPageAllText: $t('dataTable.itemsPerPageAllText'),
      }"
      :hide-default-header="!$vuetify.breakpoint.mobile"
      @update:page="fetch"
      @update:items-per-page="fetch"
    >
      <template v-if="!$vuetify.breakpoint.mobile" v-slot:header="{ props }">
        <datatable-header :props="props" :options.sync="options" :translation-key="translationKey" @update:filters="fetch"></datatable-header>
      </template>

      <template v-slot:item.created_at="{item}">
        {{ item.created_at | format_date }}
      </template>

      <template v-slot:item.expire_date="{ item }">
        <v-chip
          v-if="item.expire_date"
          :color="Date.now() > new Date(item.expire_date) ? 'error': ''"
          label
          small
        >
          {{ item.expire_date |format_date }}
        </v-chip>
      </template>
      <template v-slot:item.active="{ item }">
        <v-btn
          v-can.disable="'update_cars'"
          depressed
          data-toggle="tooltip"
          data-placement="top"
          title="تغيير الحالة"
          :color="item.active ? 'success' : 'error'"
          rounded
          small
          outlined
          @click="toggleActive(item)"
        >
          {{ item.active ? 'فعالة' : 'غير فعالة' }}
          <v-icon
            dark
            right
          >
            {{ item.active ? 'mdi-power' : 'mdi-power-off' }}
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ item.created_at | format_date }}
      </template>

      <template v-slot:item.peoples.name="{ item }">
        <span>
          {{ item.peoples.map(people => people.name).join(', ') }}
        </span>
      </template>

      <template v-slot:item.actions="{ item }">
        <div class="text-center">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                color="dark"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>
                  mdi-dots-vertical
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <menu-item v-can="'update_cars'" :label="$('update')" color="info" icon="mdi-pencil" @click="dialogModal(true, 'update', item.id)"></menu-item>
              <menu-item v-can="'delete_cars'" :label="$('delete')" color="error" icon="mdi-delete" @click="dialogModal(true, 'delete', item.id)"></menu-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
    <cars-dialog
      :dialog-modal="dialogModal"
      :data="dialogData"
      :translation-key="translationKey"
      @update:table="fetch"
    ></cars-dialog>
  </v-card>
</template>

<script>
import axios from '@axios'
import CarsDialog from '@/views/pages/cars/dialog.vue'
import DatatableHeader from '@/components/dataTables/DatatableHeader.vue'

import { ObjectToQuery } from '@/plugins/helper';
import { getFilters, getOptions } from '@/components/dataTables/helper'
import store from '@/store'
import MenuItem from '@/components/menu/menuItem.vue';
import toast from '@/store/toast'

export default {
  name: 'Cars',
  components: { DatatableHeader, CarsDialog, MenuItem },
  data: () => ({
    translationKey: 'pages.cars',
    filters: false,
    cars: [],
    carsTotal: 0,
    loading: true,
    options: {},
    tempOptions: '',
    search: '',
    dialogData: {
      dialog: false,
      type: 'create',
      id: null,
    },
    headers: [
      {
        text: 'number',
        align: 'center',
        sortable: false,
        value: 'number',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'city',
        value: 'city',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'type',
        value: 'type',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'model',
        value: 'model',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'owner',
        value: 'owner',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'color',
        value: 'color',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'toggle',
        value: 'active',
        align: 'center',
      },
      {
        text: 'expire_date',
        value: 'expire_date',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'date',
          methods: ['between', 'greater', 'less', 'equal', 'notEqual'],
        },
      },
      {
        text: 'created_at',
        value: 'created_at',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'date',
          methods: ['between', 'greater', 'less', 'equal', 'notEqual'],
        },
      },
      {
        text: 'peoples.name',
        value: 'peoples.name',
        align: 'center',
        sort: true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'actions',
        value: 'actions',
        align: 'center',
      },
    ],
  }),
  setup() {
    return {

    }
  },
  computed: {
    type() {
      return this.$route.meta.type;
    },
  },
  async mounted() {
    this.options = getOptions(this.$route, this.options);
    if (this.$route.params.id) {
      const people = await axios.get(`/peoples/${this.$route.params.id}`);
      store.state.pageTitle = this.$('people', [people.data.data.name]);
    }
    await this.fetch();
  },

  methods: {
    async fetch() {
      if (JSON.stringify(this.options) !== this.tempOptions) {
        this.tempOptions = JSON.stringify(this.options);
        await this.$router.replace({ query: this.options })
      }
      window.scrollTo(0, 0);
      localStorage.setItem(`itemsPerPage_${this.$route.name}`, this.options.itemsPerPage);
      this.loading = true;
      const filters = getFilters(this.headers);
      let query = {
        ...this.options,
        filter: this.type,
        filters,
      }
      if (this.$route.params.id) {
        query.people_id = this.$route.params.id;
      }
      query = ObjectToQuery(query);

      const response = await axios.get(`/cars/dt?${query}`);
      this.cars = response.data.data.data
      this.carsTotal = response.data.data.total
      this.loading = false
    },
    async export_all() {
      const filters = getFilters(this.headers);
      const query = ObjectToQuery({
        filter: this.type,
        filters,
      });
      axios
        .get(`cars/export?${query}`, {
          responseType: 'arraybuffer',
        })
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          const url = window.URL.createObjectURL(blob)
          window.open(url)
        })
    },
    async toggleActive(item) {
      if (this.$can('update_cars')) {
        item.active = !item.active;
        await axios.post(`/cars/edit/${item.id}`, item);
        await toast.dispatch('success', this.$t('form.success.change'))
        await this.fetch();
      }
    },
    dialogModal(dialog, type = this.dialogData.type, id = null) {
      this.dialogData.dialog = dialog;
      this.dialogData.type = type;
      this.dialogData.id = id;
    },
    $(key, params = []) {
      return this.$t(`${this.translationKey}.${key}`, params);
    },
  },

}
</script>
