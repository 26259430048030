var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",staticStyle:{"color":"white"},attrs:{"color":"#098064","small":""},on:{"click":function($event){$event.preventDefault();return _vm.export_all()}}},'v-btn',attrs,false),on),[_vm._v(" تصدير "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-file-excel ")])],1)]}}])},[_c('span',[_vm._v(" تصدير الاشخاص والمركبات ")])])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.cars,"options":_vm.options,"server-items-length":_vm.carsTotal,"loading":_vm.loading,"disable-filtering":true,"footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions:[10, 20, 50, 100, -1],
      itemsPerPageText: _vm.$t('dataTable.itemsPerPageText'),
      pageText: _vm.$t('dataTable.pageText'),
      itemsPerPageAllText: _vm.$t('dataTable.itemsPerPageAllText'),
    },"hide-default-header":!_vm.$vuetify.breakpoint.mobile},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.fetch,"update:items-per-page":_vm.fetch},scopedSlots:_vm._u([(!_vm.$vuetify.breakpoint.mobile)?{key:"header",fn:function(ref){
    var props = ref.props;
return [_c('datatable-header',{attrs:{"props":props,"options":_vm.options,"translation-key":_vm.translationKey},on:{"update:options":function($event){_vm.options=$event},"update:filters":_vm.fetch}})]}}:null,{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format_date")(item.created_at))+" ")]}},{key:"item.expire_date",fn:function(ref){
    var item = ref.item;
return [(item.expire_date)?_c('v-chip',{attrs:{"color":Date.now() > new Date(item.expire_date) ? 'error': '',"label":"","small":""}},[_vm._v(" "+_vm._s(_vm._f("format_date")(item.expire_date))+" ")]):_vm._e()]}},{key:"item.active",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{directives:[{name:"can",rawName:"v-can.disable",value:('update_cars'),expression:"'update_cars'",modifiers:{"disable":true}}],attrs:{"depressed":"","data-toggle":"tooltip","data-placement":"top","title":"تغيير الحالة","color":item.active ? 'success' : 'error',"rounded":"","small":"","outlined":""},on:{"click":function($event){return _vm.toggleActive(item)}}},[_vm._v(" "+_vm._s(item.active ? 'فعالة' : 'غير فعالة')+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" "+_vm._s(item.active ? 'mdi-power' : 'mdi-power-off')+" ")])],1)]}}],null,true)}),_c('cars-dialog',{attrs:{"dialog-modal":_vm.dialogModal,"data":_vm.dialogData,"translation-key":_vm.translationKey},on:{"update:table":_vm.fetch}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }