var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",staticStyle:{"color":"white"},attrs:{"color":"#098064","small":""},on:{"click":function($event){return _vm.dialogModal(true, 'create','call')}}},'v-btn',attrs,false),on),[_vm._v(" تصدير "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-file-excel ")])],1)]}}])},[_c('span',[_vm._v(" تصدير الاشخاص والمركبات ")])])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.customEntries,"options":_vm.options,"show-expand":"","server-items-length":_vm.customEntriesTotal,"loading":_vm.loading,"disable-filtering":true,"footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions:[10, 20, 50, 100],
      itemsPerPageText: _vm.$t('dataTable.itemsPerPageText'),
      pageText: _vm.$t('dataTable.pageText')
    },"hide-default-header":!_vm.$vuetify.breakpoint.mobile},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.fetch,"update:items-per-page":_vm.fetch},scopedSlots:_vm._u([(!_vm.$vuetify.breakpoint.mobile)?{key:"header",fn:function(ref){
    var props = ref.props;
return [_c('datatable-header',{attrs:{"props":props,"options":_vm.options,"translation-key":_vm.translationKey},on:{"update:options":function($event){_vm.options=$event},"update:filters":_vm.fetch}})]}}:null,{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',[_vm._v(" الاسم ")]),_c('th',[_vm._v(" اسم المعرف ")]),_c('th',[_vm._v(" رقم السيارة ")]),_c('th',[_vm._v(" نوع السيارة ")]),_c('th',[_vm._v(" تاريخ انتهاء السيارة ")])])]),_c('tbody',_vm._l((item.cars),function(data){return _c('tr',{key:data.id},[_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.identifier_name)+" ")]),_c('td',[_vm._v(" "+_vm._s(data.number)+" ")]),_c('td',[_vm._v(" "+_vm._s(data.type)+" ")]),_c('td',[(data.expire_date)?_c('v-chip',{attrs:{"color":Date.now() > new Date(data.expire_date) ? 'error': '',"label":"","small":""}},[_vm._v(" "+_vm._s(_vm._f("format_date")(data.expire_date))+" ")]):_c('v-chip',{attrs:{"label":"","small":""}},[_vm._v(" لا يوجد ")])],1)])}),0)]},proxy:true}],null,true)})],1)]}},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":"black","small":"","dark":""}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.user.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.user ? item.user.name : '')+" ")]}},{key:"item.checkpoint.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.checkpoint.name)+" ")]}},{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format_date")(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{directives:[{name:"can",rawName:"v-can",value:('read_cars'),expression:"'read_cars'"}],staticClass:"mx-2",attrs:{"tile":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.showImages(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-folder-multiple-image ")]),_vm._v(" صور العجلات ")],1)]}}],null,true)}),_c('export-dialog',{attrs:{"dialog-modal":_vm.dialogModal,"data":_vm.dialogData,"translation-key":_vm.translationKey},on:{"update:table":_vm.fetch}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }