var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.cars,"options":_vm.options,"server-items-length":_vm.carsTotal,"loading":_vm.loading,"disable-filtering":true,"footer-props":{
      showFirstLastPage: true,
      itemsPerPageOptions:[10, 20, 50, 100, -1],
      itemsPerPageText: _vm.$t('dataTable.itemsPerPageText'),
      pageText: _vm.$t('dataTable.pageText'),
      itemsPerPageAllText: _vm.$t('dataTable.itemsPerPageAllText'),
    },"hide-default-header":!_vm.$vuetify.breakpoint.mobile},on:{"update:options":function($event){_vm.options=$event},"update:page":_vm.fetch,"update:items-per-page":_vm.fetch},scopedSlots:_vm._u([(!_vm.$vuetify.breakpoint.mobile)?{key:"header",fn:function(ref){
    var props = ref.props;
return [_c('datatable-header',{attrs:{"props":props,"options":_vm.options,"translation-key":_vm.translationKey},on:{"update:options":function($event){_vm.options=$event},"update:filters":_vm.fetch}})]}}:null,{key:"item.created_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format_date")(item.created_at))+" ")]}},{key:"item.active",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{directives:[{name:"can",rawName:"v-can.disable",value:('update_cars'),expression:"'update_cars'",modifiers:{"disable":true}}],attrs:{"depressed":"","data-toggle":"tooltip","data-placement":"top","title":"تغيير الحالة","color":item.active ? 'success' : 'error',"rounded":"","small":"","outlined":""},on:{"click":function($event){return _vm.toggleActive(item)}}},[_vm._v(" "+_vm._s(item.active ? 'فعالة' : 'غير فعالة')+" "),_c('v-icon',{attrs:{"dark":"","right":""}},[_vm._v(" "+_vm._s(item.active ? 'mdi-power' : 'mdi-power-off')+" ")])],1)]}},{key:"item.deleted_at",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("format_date")(item.deleted_at))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","color":"dark"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-list',[_c('menu-item',{directives:[{name:"can",rawName:"v-can",value:('delete_cars'),expression:"'delete_cars'"}],attrs:{"label":_vm.$('restore'),"color":"accent","icon":"mdi-delete-restore"},on:{"click":function($event){return _vm.dialogModal(true, 'restore', item.id)}}})],1)],1)],1)]}}],null,true)}),_c('cars-dialog',{attrs:{"dialog-modal":_vm.dialogModal,"data":_vm.dialogData,"translation-key":_vm.translationKey},on:{"update:table":_vm.fetch}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }